import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { FingoDatePicker } from '../datePickers';
import dayjs from '../../config/dayjs';

function TermInput({ value, setValue, defaultDate }) {
  const handleDateChange = (newValue) => {
    setValue({ date: newValue, term: newValue.diff(dayjs(), 'days') + 1 });
  };

  const handleTermChange = ({ target }) => {
    setValue({ date: dayjs().add(target.value, 'days'), term: target.value });
  };

  useEffect(() => handleDateChange(defaultDate), []);

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Fecha de pago:</Typography>
        <FingoDatePicker
          disablePast
          gridMinWidth="50%"
          gridProps={{ width: '50%' }}
          onChange={handleDateChange}
          value={value.date}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Plazo de pago:</Typography>
        <TextField
          sx={{ width: '50%' }}
          value={value.term}
          onChange={handleTermChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">días</InputAdornment>,
          }}
          type="number"
        />
      </Stack>
    </Stack>
  );
}

TermInput.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    term: PropTypes.number,
  }),
  setValue: PropTypes.func.isRequired,
  defaultDate: PropTypes.instanceOf(dayjs),
};

TermInput.defaultProps = {
  value: {},
  defaultDate: dayjs().add(90, 'days'),
};

export default TermInput;
