import React from 'react';
import { MentionsInput } from 'react-mentions';
import makeStyles from '@mui/styles/makeStyles';

export const mentionInputClassName = 'ssky-mention-input';
export const floatingLabelClassName = 'ssky-mention-input-floating-label';

const paddingTop = '15px';
const paddingHorizontal = '10px';
const padding = `${paddingTop} ${paddingHorizontal}`;
const fontSize = 16;

const useStyles = makeStyles(({ palette, transitions }) => ({
  root: {
    width: '100%',

    '& &__control': {
      fontSize,
    },

    '& &__input': {
      border: 0,
      caretColor: palette.text.primary,
      color: 'transparent',
      padding,
      transition: transitions.create('border-bottom-color', {
        duration: transitions.duration.shorter,
        easing: transitions.easing.easeInOut,
      }),
      '&:focus': {
        borderBottom: 'none',
        outline: 'none',
      },
      '&:disabled': {
        borderBottom: 'none',
        color: palette.text.disabled,
      },
      '&:hover:not(:disabled):not(:focus)': {
        borderBottom: 'none',
      },
    },

    '& &__highlighter': {
      color: `${palette.text.primary} !important`,
      padding,
      '&__substring': {
        visibility: 'inherit !important',
      },
    },

    '& &__suggestions': {
      backgroundColor: 'white',
      border: 'none',
      boxShadow: `0 0 8px ${palette.text.primary}`,
      maxHeight: 300,
      overflow: 'auto',
      width: 'max-content',
    },

    '& &__suggestions__item': {
      alignItems: 'center',
      display: 'flex',
      height: 48,
      padding: 15,
      transition: transitions.create('background-color', {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
    },

    '& &__suggestions__item--focused': {
      backgroundColor: palette.action.selected,
    },
  },
}));

const CustomMentionsInput = (props) => {
  const classes = useStyles();
  return (
    <MentionsInput {...props} className={classes.root} />
  );
};

export default CustomMentionsInput;
