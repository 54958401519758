import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import RatificationInformationDrawer from '../drawer/RatificationInformationDrawer';
import { useGetUser } from '../../hooks';

const PERMISSION = 'debtors.add_debtorratificationinformation';

const RatificationInformationIconButton = ({ masterEntityId }) => {
  const user = useGetUser();
  const [
    openRatificationInformationDrawer,
    setOpenRatificationInformationDrawer,
  ] = useState(false);

  if (!user.permissions.includes(PERMISSION)) return null;

  return (
    <>
      <IconButton
        onClick={() => setOpenRatificationInformationDrawer(true)}
        sx={{ p: 0 }}
      >
        <CatchingPokemonIcon sx={{ color: 'error.A700' }} />
      </IconButton>
      {openRatificationInformationDrawer && (
        <RatificationInformationDrawer
          masterEntityId={masterEntityId}
          open={openRatificationInformationDrawer}
          onClose={() => setOpenRatificationInformationDrawer(false)}
        />
      )}
    </>
  );
};

RatificationInformationIconButton.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default RatificationInformationIconButton;
